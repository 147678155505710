var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('h2', [_vm._v("Adviser profile")]), _c('v-form', {
    staticClass: "adviser-form"
  }, [_c('v-text-field', {
    attrs: {
      "label": "User name",
      "data-cy": "input-username"
    },
    model: {
      value: _vm.formData.auth.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "displayName", $$v);
      },
      expression: "formData.auth.displayName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "data-cy": "input-email"
    },
    model: {
      value: _vm.formData.auth.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "email", $$v);
      },
      expression: "formData.auth.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Phone",
      "data-cy": "input-phone"
    },
    model: {
      value: _vm.formData.auth.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "phone", $$v);
      },
      expression: "formData.auth.phone"
    }
  }), _c('v-switch', {
    attrs: {
      "disabled": _vm.switchDisable,
      "label": "Disabled"
    },
    model: {
      value: _vm.formData.auth.disabled,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "disabled", $$v);
      },
      expression: "formData.auth.disabled"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Admin"
    },
    model: {
      value: _vm.formData.auth.admin,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.auth, "admin", $$v);
      },
      expression: "formData.auth.admin"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Full name",
      "data-cy": "input-fullname"
    },
    model: {
      value: _vm.formData.profile.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "fullName", $$v);
      },
      expression: "formData.profile.fullName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Registration",
      "data-cy": "input-registration"
    },
    model: {
      value: _vm.formData.profile.registration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "registration", $$v);
      },
      expression: "formData.profile.registration"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Name",
      "data-cy": "input-business-name"
    },
    model: {
      value: _vm.formData.profile.businessName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessName", $$v);
      },
      expression: "formData.profile.businessName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Registration",
      "data-cy": "input-business-registration"
    },
    model: {
      value: _vm.formData.profile.businessRegistration,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessRegistration", $$v);
      },
      expression: "formData.profile.businessRegistration"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Business Email",
      "data-cy": "input-business-email"
    },
    model: {
      value: _vm.formData.profile.businessEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.profile, "businessEmail", $$v);
      },
      expression: "formData.profile.businessEmail"
    }
  }), _c('v-file-input', {
    staticClass: "files-input",
    attrs: {
      "show-size": "",
      "chips": "",
      "disabled": _vm.fileInProcess,
      "loading": _vm.fileInProcess,
      "label": "Loan Profile"
    },
    model: {
      value: _vm.loanProfileFile.file,
      callback: function callback($$v) {
        _vm.$set(_vm.loanProfileFile, "file", $$v);
      },
      expression: "loanProfileFile.file"
    }
  }), _c('v-file-input', {
    staticClass: "files-input",
    attrs: {
      "show-size": "",
      "chips": "",
      "disabled": _vm.fileInProcess,
      "loading": _vm.fileInProcess,
      "label": "Marketing book / Disclosure statement"
    },
    model: {
      value: _vm.bookStatementFile.file,
      callback: function callback($$v) {
        _vm.$set(_vm.bookStatementFile, "file", $$v);
      },
      expression: "bookStatementFile.file"
    }
  }), _c('v-file-input', {
    staticClass: "files-input",
    attrs: {
      "show-size": "",
      "chips": "",
      "disabled": _vm.fileInProcess,
      "loading": _vm.fileInProcess,
      "label": "Authority and declaration"
    },
    model: {
      value: _vm.declarationFile.file,
      callback: function callback($$v) {
        _vm.$set(_vm.declarationFile, "file", $$v);
      },
      expression: "declarationFile.file"
    }
  }), _c('v-file-input', {
    staticClass: "files-input",
    attrs: {
      "show-size": "",
      "chips": "",
      "disabled": _vm.fileInProcess,
      "loading": _vm.fileInProcess,
      "label": "Claw back form"
    },
    model: {
      value: _vm.clawFile.file,
      callback: function callback($$v) {
        _vm.$set(_vm.clawFile, "file", $$v);
      },
      expression: "clawFile.file"
    }
  }), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isUploadedFilesExist,
      expression: "isUploadedFilesExist"
    }],
    staticClass: "my-2",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v(" Files to upload ")]), _c('v-card-text', [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" File Name ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" File Description ")])])]), _c('tbody', _vm._l(_vm.uploadedFiles, function (file, fileIdx) {
          return _c('tr', {
            key: fileIdx
          }, [_c('td', [_vm._v(_vm._s(file.file.name))]), _c('td', [_c('v-text-field', {
            staticClass: "file-description",
            attrs: {
              "type": "text",
              "disabled": ""
            },
            model: {
              value: file.description,
              callback: function callback($$v) {
                _vm.$set(file, "description", $$v);
              },
              expression: "file.description"
            }
          })], 1)]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1), _c('v-card-actions', [!_vm.fileInProcess ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.uploadFiles.apply(null, arguments);
      }
    }
  }, [_vm._v(" Upload ")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "files"
  }, [_c('v-subheader', [_vm._v("YOUR FILES")]), _c('div', {
    staticClass: "files-table"
  }, [_c('div', {
    staticClass: "files-table__header"
  }, [_c('span', {
    staticClass: "files-table__label text-center col-3"
  }, [_vm._v("File Name")]), _c('span', {
    staticClass: "files-table__label text-center col-3"
  }, [_vm._v("File Description")]), _c('span', {
    staticClass: "files-table__label text-center col-2"
  }, [_vm._v("Size")]), _c('span', {
    staticClass: "files-table__label text-center col-2"
  }, [_vm._v("Created At")]), _c('span', {
    staticClass: "files-table__label text-center col-1"
  }), _c('span', {
    staticClass: "files-table__label text-center col-1"
  })]), _c('div', {
    staticClass: "files-table__content"
  }, [_c('Draggable', _vm._b({
    attrs: {
      "move": _vm.changeFileOrder
    },
    on: {
      "start": function start($event) {
        _vm.isDragging = true;
      },
      "end": function end($event) {
        _vm.isDragging = false;
      }
    },
    model: {
      value: _vm.storedFilesDraggable,
      callback: function callback($$v) {
        _vm.storedFilesDraggable = $$v;
      },
      expression: "storedFilesDraggable"
    }
  }, 'Draggable', _vm.dragOptions, false), _vm._l(_vm.sortedStoredFiles, function (file, fileIdx) {
    return _c('div', {
      key: fileIdx,
      staticClass: "files-table__item"
    }, [_c('div', {
      staticClass: "col-3"
    }, [_vm._v(_vm._s(file.filename))]), _c('div', {
      staticClass: "col-3"
    }, [_c('v-text-field', {
      staticClass: "without-indents file-description",
      attrs: {
        "disabled": "",
        "type": "text"
      },
      on: {
        "blur": function blur($event) {
          return _vm.fileDescriptionEdit(file);
        }
      },
      model: {
        value: file.description,
        callback: function callback($$v) {
          _vm.$set(file, "description", $$v);
        },
        expression: "file.description"
      }
    })], 1), _c('div', {
      staticClass: "col-2"
    }, [_vm._v(_vm._s(_vm._f("formatBytes")(file.size)))]), _c('div', {
      staticClass: "col-2"
    }, [_vm._v(_vm._s(file.timeCreated))]), _c('div', {
      staticClass: "col-1"
    }, [_c('v-btn', {
      attrs: {
        "disabled": _vm.fileInProcess
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.download(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1), _c('div', {
      staticClass: "col-1"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "disabled": _vm.fileInProcess
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.moveToArchive(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete-outline")])], 1)], 1)]);
  }), 0)], 1)])], 1), _c('br'), _c('div', [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "loading": _vm.resetLoading
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" RESET PASSWORD ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" CONFIRM ACTION ")]), _c('v-card-text', [_vm._v("Save user data and send email " + _vm._s(_vm.formData.auth.email) + "?")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" CANCEL ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.confirmReset
    }
  }, [_vm._v(" CONFIRM ")])], 1)], 1)], 1)], 1), _c('br'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.saving,
      "data-cy": "btn-save"
    },
    on: {
      "click": _vm.clickSave
    }
  }, [_vm._v("SAVE ")]), _c('br')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }